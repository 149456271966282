import { ParameterPresence, TextExpansion } from "@remhealth/apollo";
import { ImportProps } from "./common";

export async function importTextExpansions(props: ImportProps): Promise<TextExpansion[]> {
  const {
    sourceClient,
    targetClient,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  const sourceTextExpansions = await sourceClient.textExpansions.feed({
    filters: [{ author: { presence: ParameterPresence.NotPresent } }],
  }).all({ abort });

  onUpdate(sourceTextExpansions.length);
  if (sourceTextExpansions.length === 0) {
    return [];
  }

  const targetTextExpansions = await targetClient.textExpansions.feed({
    filters: [{ author: { presence: ParameterPresence.NotPresent }, includeDeleted: true }],
  }).all({ abort });
  const textExpansions = targetTextExpansions.filter(f => !f.meta?.isDeleted);

  const results = [];
  for (const textExpansion of sourceTextExpansions) {
    onItemCreate(textExpansion.id, textExpansion.name);

    if (targetTextExpansions.some(d => d.id === textExpansion.id)) {
      onItemSkip(textExpansion.id, "IdExisted");
      continue;
    }

    const name = textExpansion.name.trim().toLowerCase();
    if (textExpansions.some(d => d.name.toLowerCase() === name)) {
      onItemError(textExpansion.id, "Duplicate name", true);
      continue;
    }

    try {
      const result = await targetClient.textExpansions.update({ ...textExpansion, meta: undefined }, { abort });
      results.push(result);
      onItemComplete(textExpansion.id);
    } catch (error) {
      onItemError(textExpansion.id, "Failed to create", true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return results;
}
