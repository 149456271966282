import { HealthcareService, NoteDefinition, NoteSectionDefinition, NoteSectionForm, Program, Reference, Snippet } from "@remhealth/apollo";
import { createVersionedReference } from "@remhealth/host";
import { createReferenceMapperById, createReferenceMapperByIdentifier } from "./referenceMapper";
import { importNoteSectionForms } from "./noteSectionForms";
import { ImportProps } from "./common";

export async function importNoteDefinitions(props: ImportProps): Promise<NoteDefinition[]> {
  const {
    sourceClient,
    targetClient,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  let sourceNoteDefinitions = await sourceClient.noteDefinitions.feed({
    filters: [{
      status: {
        matches: "Active",
      },
    }],
  }).all({ abort });

  onUpdate(sourceNoteDefinitions.length);
  if (sourceNoteDefinitions.length === 0) {
    return [];
  }

  const targetNoteDefinitions = await targetClient.noteDefinitions.feed({
    filters: [{
      status: {
        matches: "Active",
      },
      includeDeleted: true,
    }],
  }).all({ abort });
  const noteDefinitions = targetNoteDefinitions.filter(f => !f.meta?.isDeleted);

  const sourceServices: Reference<HealthcareService>[] = [];
  const sourcePrograms: Reference<Program>[] = [];
  const sourceSnippets: Reference<Snippet>[] = [];
  const sourceNoteSectionForms: Reference<NoteSectionForm>[] = [];

  sourceNoteDefinitions = sourceNoteDefinitions.filter(item => {
    onItemCreate(item.id, item.name);

    if (targetNoteDefinitions.some(d => d.id === item.id)) {
      onItemSkip(item.id, "IdExisted");
      return false;
    }

    const name = item.name.trim().toLowerCase();
    if (noteDefinitions.some(d => d.name.trim().toLowerCase() === name && d.type === item.type)) {
      onItemError(item.id, "Duplicate name", true);
      return false;
    }

    sourceServices.push(...item.services);
    sourcePrograms.push(...item.programs);
    sourceServices.push(...item.auditing.services);

    item.sections.forEach(s => {
      if (s.form) {
        sourceNoteSectionForms.push(s.form);
      }
      if (s.defaultComment) {
        sourceSnippets.push(s.defaultComment);
      }
    });

    return true;
  });

  const noteSectionForms = new Map<string, NoteSectionForm>();
  const pageSize = 100;
  for (let i = 0; i < sourceNoteSectionForms.length; i += pageSize) {
    const page = sourceNoteSectionForms.slice(i, i + pageSize);
    const targetItems = await targetClient.noteSectionForms.feed({
      filters: [{ ids: page.map(i => i.id), includeDeleted: true }],
    }).all({ abort });

    for (const targetItem of targetItems) {
      noteSectionForms.set(targetItem.id, targetItem);
    }
  }

  const missingNoteSectionForms = sourceNoteSectionForms.filter(f => !noteSectionForms.has(f.id));
  if (missingNoteSectionForms.length) {
    const newNoteSectionForms = await importNoteSectionForms(
      {
        ...props,
        onItemCreate: (id: string, name: string) => onItemCreate(id, `CNS: ${name}`),
      },
      missingNoteSectionForms
    );

    newNoteSectionForms.forEach(f => {
      if (!noteSectionForms.has(f.id)) {
        noteSectionForms.set(f.id, f);
      }
    });
  }

  const serviceReferenceMapper = await createReferenceMapperByIdentifier(sourceClient.healthcareServices, targetClient.healthcareServices, sourceServices, abort);
  const programReferenceMapper = await createReferenceMapperByIdentifier(sourceClient.programs, targetClient.programs, sourcePrograms, abort);
  const snippetReferenceMapper = await createReferenceMapperById(targetClient.snippets, sourceSnippets, abort);

  const results = [];
  for (const noteDefinition of sourceNoteDefinitions) {
    const item = copyNoteDefinition(noteDefinition);
    if (item) {
      try {
        const result = await targetClient.noteDefinitions.update({ ...item, meta: undefined }, { abort });
        results.push(result);
        onItemComplete(noteDefinition.id);
      } catch (error) {
        onItemError(noteDefinition.id, "Failed to create", true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  return results;

  function copyNoteDefinition(noteDefinition: NoteDefinition): NoteDefinition | null {
    const services = serviceReferenceMapper.map(noteDefinition.services, (unmatched) => {
      onItemError(noteDefinition.id, `Service "${unmatched.display}" not found`);
    });
    const programs = programReferenceMapper.map(noteDefinition.programs, (unmatched) => {
      onItemError(noteDefinition.id, `Program "${unmatched.display}" not found`);
    });
    const auditingServices = serviceReferenceMapper.map(noteDefinition.auditing.services, (unmatched) => {
      onItemError(noteDefinition.id, `Service "${unmatched.display}" not found in auditing`);
    });

    const sections: NoteSectionDefinition[] = [];
    for (const s of noteDefinition.sections) {
      const section: NoteSectionDefinition = {
        ...s,
        form: undefined,
        defaultComment: undefined,
      };

      if (s.form) {
        const form = noteSectionForms.get(s.form.id);
        if (form) {
          section.form = createVersionedReference(form);
        } else {
          onItemError(noteDefinition.id, `Form "${s.form.display}" not found in section "${s.name}"`);
          continue;
        }
      }

      if (s.defaultComment) {
        const defaultComment = snippetReferenceMapper.map(s.defaultComment);
        if (defaultComment) {
          section.defaultComment = defaultComment;
        } else {
          onItemError(noteDefinition.id, `Snippet "${s.defaultComment.display}" not found for default comment in section "${s.name}"`);
        }
      }

      sections.push(section);
    }

    if (sections.length === 0) {
      onItemError(noteDefinition.id, "No valid section", true);
      return null;
    }

    return {
      ...noteDefinition,
      services,
      programs,
      auditing: {
        ...noteDefinition.auditing,
        services: auditingServices,
      },
      sections,
    };
  }
}
