import { GroupNoteDefinition, NoteDefinition, NoteTemplate, Reference } from "@remhealth/apollo";
import { createReferenceMapperById } from "./referenceMapper";
import { ImportProps } from "./common";

export async function importGroupNoteDefinitions(props: ImportProps): Promise<GroupNoteDefinition[]> {
  const {
    sourceClient,
    targetClient,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  let sourceGroupNoteDefinitions = await sourceClient.groupNoteDefinitions.feed({
    filters: [{
    }],
  }).all({ abort });

  onUpdate(sourceGroupNoteDefinitions.length);
  if (sourceGroupNoteDefinitions.length === 0) {
    return [];
  }

  const targetGroupNoteDefinitions = await targetClient.groupNoteDefinitions.feed({
    filters: [{
      includeDeleted: true,
    }],
  }).all({ abort });
  const groupNoteDefinitions = targetGroupNoteDefinitions.filter(f => !f.meta?.isDeleted);

  const sourceNoteDefinitions: Reference<NoteDefinition>[] = [];
  const sourceNoteTemplates: Reference<NoteTemplate>[] = [];

  sourceGroupNoteDefinitions = sourceGroupNoteDefinitions.filter(item => {
    onItemCreate(item.id, item.name);

    if (targetGroupNoteDefinitions.some(d => d.id === item.id)) {
      onItemSkip(item.id, "IdExisted");
      return false;
    }

    const name = item.name.trim().toLowerCase();
    if (groupNoteDefinitions.some(d => d.name.trim().toLowerCase() === name)) {
      onItemError(item.id, "Duplicate name", true);
      return false;
    }

    sourceNoteDefinitions.push(item.cancelled);
    sourceNoteDefinitions.push(item.noShow);
    sourceNoteDefinitions.push(item.show);
    sourceNoteDefinitions.push(...item.alternativeShowNoteDefinitions);

    if (item.cancelledTemplate) {
      sourceNoteTemplates.push(item.cancelledTemplate);
    }
    if (item.noShowTemplate) {
      sourceNoteTemplates.push(item.noShowTemplate);
    }
    if (item.showTemplate) {
      sourceNoteTemplates.push(item.showTemplate);
    }
    sourceNoteTemplates.push(...item.alternativeShowNoteTemplates);

    return true;
  });

  const noteDefinitionReferenceMapper = await createReferenceMapperById(targetClient.noteDefinitions, sourceNoteDefinitions, abort);
  const noteTemplateReferenceMapper = await createReferenceMapperById(targetClient.noteTemplates, sourceNoteTemplates, abort);

  const results = [];
  for (const groupNoteDefinition of sourceGroupNoteDefinitions) {
    const item = copyGroupNoteDefinition(groupNoteDefinition);
    if (item) {
      try {
        const result = await targetClient.groupNoteDefinitions.update({ ...item, meta: undefined }, { abort });
        results.push(result);
        onItemComplete(groupNoteDefinition.id);
      } catch (error) {
        onItemError(groupNoteDefinition.id, "Failed to create", true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  return results;

  function copyGroupNoteDefinition(groupNoteDefinition: GroupNoteDefinition): GroupNoteDefinition | null {
    const cancelled = noteDefinitionReferenceMapper.map(groupNoteDefinition.cancelled);
    if (!cancelled) {
      onItemError(groupNoteDefinition.id, `Note type "${groupNoteDefinition.cancelled.display}" not found`, true);
      return null;
    }
    const noShow = noteDefinitionReferenceMapper.map(groupNoteDefinition.noShow);
    if (!noShow) {
      onItemError(groupNoteDefinition.id, `Note type "${groupNoteDefinition.noShow.display}" not found`, true);
      return null;
    }
    const show = noteDefinitionReferenceMapper.map(groupNoteDefinition.show);
    if (!show) {
      onItemError(groupNoteDefinition.id, `Note type "${groupNoteDefinition.show.display}" not found for show`, true);
      return null;
    }

    const alternativeShowNoteDefinitions = noteDefinitionReferenceMapper.map(groupNoteDefinition.alternativeShowNoteDefinitions, (unmatched) => {
      onItemError(groupNoteDefinition.id, `Note type "${unmatched.display}" not found`);
    });
    const alternativeShowNoteTemplates = noteTemplateReferenceMapper.map(groupNoteDefinition.alternativeShowNoteTemplates, (unmatched) => {
      onItemError(groupNoteDefinition.id, `Note template "${unmatched.display}" not found`);
    });

    let cancelledTemplate: Reference<NoteTemplate> | undefined;
    if (groupNoteDefinition.cancelledTemplate) {
      cancelledTemplate = noteTemplateReferenceMapper.map(groupNoteDefinition.cancelledTemplate);
      if (!cancelledTemplate) {
        onItemError(groupNoteDefinition.id, `Note template "${groupNoteDefinition.cancelledTemplate.display}" not found`);
      }
    }
    let noShowTemplate: Reference<NoteTemplate> | undefined;
    if (groupNoteDefinition.noShowTemplate) {
      noShowTemplate = noteTemplateReferenceMapper.map(groupNoteDefinition.noShowTemplate);
      if (!noShowTemplate) {
        onItemError(groupNoteDefinition.id, `Note template "${groupNoteDefinition.noShowTemplate.display}" not found`);
      }
    }
    let showTemplate: Reference<NoteTemplate> | undefined;
    if (groupNoteDefinition.showTemplate) {
      showTemplate = noteTemplateReferenceMapper.map(groupNoteDefinition.showTemplate);
      if (!showTemplate) {
        onItemError(groupNoteDefinition.id, `Note template "${groupNoteDefinition.showTemplate.display}" not found`);
      }
    }

    return {
      ...groupNoteDefinition,
      cancelled,
      noShow,
      show,
      alternativeShowNoteDefinitions,
      alternativeShowNoteTemplates,
      cancelledTemplate,
      noShowTemplate,
      showTemplate,
    };
  }
}
