import { Group, type GroupNoteDefinition, type Reference } from "@remhealth/apollo";
import { ImportProps } from "./common";
import { createReferenceMapperById, createReferenceMapperByIdentifier } from "./referenceMapper";

export async function updateGroups(props: ImportProps): Promise<Group[]> {
  const {
    sourceClient,
    targetClient,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  let targetGroups = [];

  const groupFeed = targetClient.groups.feed({
    filters: [{
      identifier: { presence: "MustBePresent" },
    }],
  });
  const pageSize = 100;
  do {
    const page = await groupFeed.next(pageSize, { abort });

    targetGroups.push(...page);
  } while (groupFeed.hasMoreResults && !abort.aborted);

  onUpdate(targetGroups.length);
  if (targetGroups.length === 0) {
    return [];
  }

  const sourceGroupReferenceMapper = await createReferenceMapperByIdentifier(targetClient.groups, sourceClient.groups, targetGroups, abort);

  const sourceGroupNoteDefinitions: Reference<GroupNoteDefinition>[] = [];

  targetGroups = targetGroups.filter(item => {
    onItemCreate(item.id, item.name ?? item.id);

    const sourceGroup = sourceGroupReferenceMapper.map(item);
    if (!sourceGroup) {
      onItemSkip(item.id, "SourceNotFound");
      return false;
    }

    if (sourceGroup.definition) {
      sourceGroupNoteDefinitions.push(sourceGroup.definition);
    }

    sourceGroupNoteDefinitions.push(...sourceGroup.alternativeDefinitions);

    return true;
  });

  const groupNoteDefinitionReferenceMapper = await createReferenceMapperById(targetClient.groupNoteDefinitions, sourceGroupNoteDefinitions, abort);

  const results = [];
  for (const group of targetGroups) {
    const sourceGroup = sourceGroupReferenceMapper.map(group);
    if (!sourceGroup) {
      continue;
    }

    let updated = false;
    if (sourceGroup.definition && sourceGroup.definition.id !== group.definition?.id) {
      const definition = groupNoteDefinitionReferenceMapper.map(sourceGroup.definition);
      if (definition) {
        group.definition = definition;
        updated = true;
      } else {
        onItemError(group.id, `Group note definition "${sourceGroup.definition.display}" not found`);
      }
    }

    for (const sourceAlternativeDefinition of sourceGroup.alternativeDefinitions) {
      if (group.alternativeDefinitions.some(d => d.id === sourceAlternativeDefinition.id)) {
        continue;
      }

      const alternativeDefinition = groupNoteDefinitionReferenceMapper.map(sourceAlternativeDefinition);
      if (!alternativeDefinition) {
        onItemError(group.id, `Group note definition "${sourceAlternativeDefinition.display}" not found for alternative definition`);
        continue;
      }
      group.alternativeDefinitions.push(alternativeDefinition);
      updated = true;
    }

    if (!updated) {
      onItemSkip(group.id, "NoUpdate");
      continue;
    }

    try {
      const result = await targetClient.groups.update(group, { abort });
      results.push(result);
      onItemComplete(group.id);
    } catch (error) {
      onItemError(group.id, "Failed to update", true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return results;
}
