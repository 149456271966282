import { Group, Itinerary, Reference } from "@remhealth/apollo";
import { createReferenceMapperByIdentifier } from "./referenceMapper";
import { ImportProps } from "./common";

export async function importItineraries(props: ImportProps): Promise<Itinerary[]> {
  const {
    sourceClient,
    targetClient,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  let sourceItineraries = await sourceClient.itineraries.feed({
    filters: [],
  }).all({ abort });

  onUpdate(sourceItineraries.length);
  if (sourceItineraries.length === 0) {
    return [];
  }

  const targetItineraries = await targetClient.itineraries.feed({
    filters: [{ includeDeleted: true }],
  }).all({ abort });
  const itineraries = targetItineraries.filter(f => !f.meta?.isDeleted);

  const sourceGroups: Reference<Group>[] = [];

  sourceItineraries = sourceItineraries.filter(item => {
    onItemCreate(item.id, item.display ?? item.id);

    if (targetItineraries.some(d => d.id === item.id)) {
      onItemSkip(item.id, "IdExisted");
      return false;
    }

    if (itineraries.some(d => d.group.id === item.group.id)) {
      onItemError(item.id, `Another itinerary associated with group ${item.group.display}`, true);
      return false;
    }

    sourceGroups.push(item.group);
    return true;
  });

  const groupReferenceMapper = await createReferenceMapperByIdentifier(sourceClient.groups, targetClient.groups, sourceGroups, abort);

  const results = [];
  for (const itinerary of sourceItineraries) {
    const item = copyItinerary(itinerary);
    if (item) {
      try {
        const result = await targetClient.itineraries.update({ ...item, meta: undefined }, { abort });
        results.push(result);
        onItemComplete(itinerary.id);
      } catch (error) {
        onItemError(itinerary.id, "Failed to create", true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  return results;

  function copyItinerary(itinerary: Itinerary): Itinerary | null {
    const group = groupReferenceMapper.map(itinerary.group);
    if (!group) {
      onItemError(itinerary.id, `Group ${itinerary.group.display} not found`, true);
      return null;
    }

    return { ...itinerary, group };
  }
}
