import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { Classes, TabsProps as CoreTabsProps, ResizeSensor, TabId, Utils } from "@blueprintjs/core";
import { useUpdateEffect } from "../hooks";
import { FormArea, FormScopeContext } from "./formScope";
import { TabElement, TabProps, isTabElement } from "./tab";
import { TabTitle, generateTabPanelId, generateTabTitleId } from "./tabTitle";
import { Container } from "./tabs.styles";

export interface TabsProps extends CoreTabsProps {
  /**
   * Render a selected tab indicator
   * @default true
   */
  indicator?: boolean;

  /**
   * Whether the tab list will stick to the top of its container.
   * (Only applies when vertical=true)
   * @default false
   */
  sticky?: boolean;

  /**
   * Whether the tab list will be full width and height of its container
   * @default false
   */
  fill?: boolean;

  /**
   * Whether tabs will be rendered as their own form area.
   * @default true
   */
  includeFormAreas?: boolean;
}

const tabSelector = `.${Classes.TAB}`;

export const Tabs = (props: TabsProps) => {
  const {
    animate = true,
    className,
    fill = false,
    large = false,
    indicator = true,
    sticky = false,
    includeFormAreas = true,
    renderActiveTabPanelOnly = false,
    vertical = false,
    defaultSelectedTabId,
    selectedTabId: controlledSelectedTabId,
    children,
    id,
    onChange,
  } = props;

  const formScopeContext = useContext(FormScopeContext);

  const [indicatorWrapperStyle, setIndicatorWrapperStyle] = useState<CSSProperties>();
  const [uncontrolledSelectedTabId, setUncontrolledSelectedTabId] = useState<TabId | undefined>(getInitialSelectedTabId);

  const tablistRef = useRef<HTMLDivElement>(null);
  const lastTabProps = useRef<TabProps[]>();

  const selectedTabId = controlledSelectedTabId ?? uncontrolledSelectedTabId;

  const tabIds = getTabIds();
  const tabTitles = React.Children.map(props.children, renderTabTitle);

  const tabPanels = getTabChildren()
    .filter(renderActiveTabPanelOnly ? tab => tab.props.id === selectedTabId : () => true)
    .map(renderTabPanel);

  const tabIndicator = animate ? (
    <div className={Classes.TAB_INDICATOR_WRAPPER} style={indicatorWrapperStyle}>
      <div className={Classes.TAB_INDICATOR} />
    </div>
  ) : null;

  const classes = classNames(Classes.TABS, className, {
    [Classes.VERTICAL]: vertical,
    fill,
    large,
    indicator,
    sticky,
  });

  useEffect(() => {
    moveSelectionIndicator(false);
  }, []);

  useUpdateEffect(() => {
    moveSelectionIndicator(true);
  }, [selectedTabId]);

  useEffect(() => {
    const tabProps = getTabChildrenProps();
    if (lastTabProps.current) {
      const didChildrenChange = !Utils.arraysEqual(lastTabProps.current, tabProps, Utils.shallowCompareKeys);

      if (didChildrenChange) {
        moveSelectionIndicator(true);
      }
    }

    lastTabProps.current = tabProps;
  });

  return (
    <Container
      $id={id}
      $tabIds={tabIds}
      className={classes}
    >
      <ResizeSensor targetRef={tablistRef} onResize={handleResize}>
        <div
          ref={tablistRef}
          className={Classes.TAB_LIST}
          data-form-name={formScopeContext.form}
          role="tablist"
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyPress}
        >
          {tabIndicator}
          {tabTitles}
        </div>
      </ResizeSensor>
      {tabPanels}
    </Container>
  );

  function getInitialSelectedTabId() {
    // NOTE: providing an unknown ID will hide the selection
    if (controlledSelectedTabId !== undefined) {
      return controlledSelectedTabId;
    } else if (defaultSelectedTabId !== undefined) {
      return defaultSelectedTabId;
    }
    // Select first tab in absence of user input
    const tabs = getTabChildren();
    return tabs.length === 0 ? undefined : tabs[0].props.id;
  }

  function getKeyCodeDirection(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === "ArrowLeft" || e.key === "ArrowUp") {
      return -1;
    } else if (e.key === "ArrowRight" || e.key === "ArrowDown") {
      return 1;
    }
    return undefined;
  }

  function getTabChildrenProps() {
    return getTabChildren().map(child => child.props);
  }

  /** Filters children to only `<Tab>`s */
  function getTabChildren() {
    return React.Children.toArray(children).filter(isTabElement);
  }

  /** Queries root HTML element for all tabs with optional filter selector */
  function getTabElements(subselector = "") {
    if (!tablistRef.current) {
      return [];
    }
    return Array.from(tablistRef.current.querySelectorAll(tabSelector + subselector));
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    const focusedElement = Utils.getActiveElement(tablistRef.current)?.closest(tabSelector);
    // Rest of this is potentially expensive and futile, so bail if no tab is focused
    if (focusedElement == null) {
      return;
    }

    // Must rely on DOM state because we have no way of mapping `focusedElement` to a JSX.Element
    const enabledTabElements = getTabElements().filter(el => el.getAttribute("aria-disabled") === "false");
    const focusedIndex = enabledTabElements.indexOf(focusedElement);
    const direction = getKeyCodeDirection(e);

    if (focusedIndex >= 0 && direction !== undefined) {
      e.preventDefault();
      const { length } = enabledTabElements;
      // Auto-wrapping at 0 and `length`
      const nextFocusedIndex = (focusedIndex + direction + length) % length;
      (enabledTabElements[nextFocusedIndex] as HTMLElement).focus();
    }
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLDivElement>) {
    const targetTabElement = (e.target as HTMLElement).closest<HTMLElement>(tabSelector);
    if (targetTabElement != null && Utils.isKeyboardClick(e)) {
      e.preventDefault();
      targetTabElement.click();
    }
  }

  function handleTabClick(newTabId: TabId, event: React.MouseEvent<HTMLElement>) {
    onChange?.(newTabId, selectedTabId, event);
    setUncontrolledSelectedTabId(newTabId);
  }

  function handleResize() {
    moveSelectionIndicator(false);
  }

  /**
   * Calculate the new height, width, and position of the tab indicator.
   * Store the CSS values so the transition animation can start.
   */
  function moveSelectionIndicator(canAnimate: boolean) {
    if (!tablistRef.current || !animate) {
      return;
    }

    const tabIdSelector = `${tabSelector}[data-tab-id="${selectedTabId}"]`;
    const selectedTabElement = tablistRef.current.querySelector<HTMLElement>(tabIdSelector);

    let indicatorWrapperStyle: React.CSSProperties = { display: "none" };
    if (selectedTabElement != null) {
      const { clientHeight, clientWidth, offsetLeft, offsetTop } = selectedTabElement;
      indicatorWrapperStyle = {
        height: clientHeight,
        transform: `translateX(${Math.floor(offsetLeft)}px) translateY(${Math.floor(offsetTop)}px)`,
        width: clientWidth,
      };

      if (!canAnimate) {
        indicatorWrapperStyle.transition = "none";
      }
    }

    setIndicatorWrapperStyle(indicatorWrapperStyle);
  }

  function renderTabPanel(tab: TabElement) {
    const { className, panel, id: tabId, title, panelClassName } = tab.props;
    if (panel === undefined) {
      return undefined;
    }

    const tabTitleId = generateTabTitleId(id, tabId);
    const tabPanelId = generateTabPanelId(id, tabId);

    return (
      <FormArea
        key={tabId}
        aria-hidden={tabId !== selectedTabId}
        aria-labelledby={tabTitleId}
        className={classNames(Classes.TAB_PANEL, className, panelClassName)}
        form={includeFormAreas ? `${formScopeContext.form ? formScopeContext.form + " " : ""}${title} Tab` : formScopeContext.form}
        id={tabPanelId}
        role="tabpanel"
      >
        {Utils.isFunction(panel) ? panel({ tabTitleId, tabPanelId }) : panel}
      </FormArea>
    );
  }

  function getTabIds(): TabId[] {
    const tabIds = React.Children.map(props.children, child => isTabElement(child) ? child.props.id : undefined);
    return tabIds?.flatMap(id => id ?? []) ?? [];
  }

  function renderTabTitle(child: React.ReactNode) {
    if (isTabElement(child)) {
      const { id: tabId } = child.props;
      return (
        <TabTitle
          aria-label={child.props.title}
          {...child.props}
          parentId={id}
          selected={tabId === selectedTabId}
          onClick={handleTabClick}
        />
      );
    }
    return child;
  }
};

export const TabHeader = styled.div`
  margin: 20px 0;
  font-size: var(--font-size-small);
  color: var(--gray2);
  text-transform: uppercase;
  word-wrap: break-word;
`;
