import { pathCombine, sanitizeBaseUrl } from "@remhealth/ui";

export type Platform =
  | "Agnostic"
  | "Win"
  | "Mac"
  | "Linux"
  | "iOS"
  | "iPhone"
  | "iPad"
  | "iWatch"
  | "Android"
  | "Docker";

export type Arch =
  | "Agnostic"
  | "x86"
  | "x64"
  | "arm64";

export const AppStatus = {
  Forbidden: "Forbidden",
  Obsolete: "Obsolete",
  Usable: "Usable",
} as const;
export type AppStatus = typeof AppStatus[keyof typeof AppStatus];

export interface Build {
  arch: Arch;
  artifact: string;
  artifactName: string;
  buildNumber?: number;
  commit?: string;
  created: string;
  display: string;
  downloadUrl?: string;
  id: string;
  overallStatus: AppStatus;
  platform: Platform;
  published: string;
  releaseNotes?: string;
  retirementNotes?: string;
  status: AppStatus;
  updated: string;
  version: string;
}

export interface Artifact {
  id: string;
  name: string;
  status: AppStatus;
  bindings: ArtifactBinding[];
}

export interface ArtifactBinding {
  platform: Platform;
  arch: Arch;
  status: AppStatus;
  order: number;
  channels: ArtifactChannel[];
}

export interface ArtifactChannel {
  key: string;
  name: string;
  status: AppStatus;
  order: number;
  follow: boolean;
  isLatest: boolean;
  isBound: boolean;
  buildGuid?: string;
  boundBuildGuid?: string;
  latestBuildGuid?: string;
  storeLink?: string;
}

export interface Channel {
  arch: Arch;
  artifact: string;
  buildGuid?: string;
  channel: string;
  platform: Platform;
}

export interface SetChannelBuild {
  arch: Arch;
  artifact: string;
  buildGuid: string | null;
  channel: string;
  platform: Platform;
}

export interface SetChannelFollow {
  arch: Arch;
  artifact: string;
  follow: boolean;
  channel: string;
  platform: Platform;
}

export interface SetBuildStatusRequest {
  buildGuid: string;
  status: AppStatus;
}

export interface SetBuildReleaseNotesRequest {
  buildGuid: string;
  notes: string | null;
}

export interface SetBuildRetirementNotesRequest {
  buildGuid: string;
  notes: string | null;
}

export interface QueryResults<T> {
  results: T[];
  totalCount?: number;
}

export class ConfigService {
  private readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = sanitizeBaseUrl(baseUrl);
  }

  public async getArtifact(accessToken: string, artifact: string, abort: AbortSignal): Promise<Artifact | null> {
    const response = await this.fetch({
      url: pathCombine(this.baseUrl, "/api/updates/artifacts", encodeURIComponent(artifact)),
      accessToken,
      validStatuses: [200, 404],
      method: "GET",
      abort,
    });

    if (response.status === 200) {
      return await response.json() as Artifact;
    }

    return null;
  }

  public async queryBuilds(accessToken: string, artifact: string, platform: Platform, arch: Arch, startIndex: number, limit: number, abort: AbortSignal): Promise<QueryResults<Build>> {
    const response = await this.fetch({
      url: pathCombine(this.baseUrl, "/api/updates/builds", encodeURIComponent(artifact), platform, arch)
        + `?start=${startIndex}&limit=${limit}`,
      accessToken,
      method: "GET",
      abort,
    });

    const results = await response.json() as Build[];
    const totalCount = response.headers.get("x-total-count");
    return { results, totalCount: totalCount !== null ? Number.parseInt(totalCount, 10) : undefined };
  }

  public async getChannel(accessToken: string, artifact: string, platform: Platform, arch: Arch, channel: string, abort?: AbortSignal): Promise<Channel | null> {
    const response = await this.fetch({
      url: pathCombine(this.baseUrl, "/api/updates/channels", encodeURIComponent(artifact), platform, arch, channel),
      accessToken,
      method: "GET",
      abort,
    });

    return await response.json() ?? null as Channel | null;
  }

  public async setChannelBuild(accessToken: string, request: SetChannelBuild, abort?: AbortSignal): Promise<Channel> {
    const response = await this.fetch({
      url: pathCombine(this.baseUrl, "/api/updates/channels/_setBuild"),
      accessToken,
      method: "POST",
      body: request,
      abort,
    });

    return await response.json() as Channel;
  }

  public async setChannelFollow(accessToken: string, request: SetChannelFollow, abort?: AbortSignal): Promise<Channel> {
    const response = await this.fetch({
      url: pathCombine(this.baseUrl, "/api/updates/channels/_setFollow"),
      accessToken,
      method: "POST",
      body: request,
      abort,
    });

    return await response.json() as Channel;
  }

  public async setBuildStatus(accessToken: string, request: SetBuildStatusRequest, abort?: AbortSignal): Promise<Build> {
    const response = await this.fetch({
      url: pathCombine(this.baseUrl, "/api/updates/builds/_setStatus"),
      accessToken,
      method: "POST",
      body: request,
      abort,
    });

    return await response.json() as Build;
  }

  public async setBuildReleaseNotes(accessToken: string, request: SetBuildReleaseNotesRequest, abort?: AbortSignal): Promise<Build> {
    const response = await this.fetch({
      url: pathCombine(this.baseUrl, "/api/updates/builds/_setReleaseNotes"),
      accessToken,
      method: "POST",
      body: request,
      abort,
    });

    return await response.json() as Build;
  }

  public async setBuildRetirementNotes(accessToken: string, request: SetBuildRetirementNotesRequest, abort?: AbortSignal): Promise<Build> {
    const response = await this.fetch({
      url: pathCombine(this.baseUrl, "/api/updates/builds/_setRetirementNotes"),
      accessToken,
      method: "POST",
      body: request,
      abort,
    });

    return await response.json() as Build;
  }

  private async fetch(request: FetchRequest): Promise<Response> {
    const headers: Record<string, string> = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Cache-Control": "private,no-cache, no-store, max-age=0",
    };

    if (request.accessToken) {
      headers.Authorization = `Bearer ${request.accessToken}`;
    }

    return await retryIfTransient(request.validStatuses ?? [200], () => fetch(request.url, {
      method: request.method ?? "GET",
      body: request.body ? JSON.stringify(request.body) : undefined,
      headers,
      signal: request.abort,
    }));
  }
}

interface FetchRequest {
  accessToken?: string;
  method?: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  validStatuses?: number[];
  body?: any;
  abort?: AbortSignal;
}

async function retryIfTransient(validStatuses: number[], request: () => Promise<Response>): Promise<Response> {
  let retries = 3;

  do {
    retries--;

    const response = await request();

    if (isTransientFailure(response.status) && retries > 0) {
      continue;
    }

    if (!validStatuses.includes(response.status)) {
      throw new Error(`Version Status service returned unexpected status ${response.status}.`);
    }

    return response;
  } while (true);
}

function isTransientFailure(status: number) {
  return status === 429 || status > 500;
}
