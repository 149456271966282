import React, { useContext } from "react";
import { PracticeClients, RegistryClients } from "@remhealth/apollo";
import type { Zone } from "@remhealth/host";
import type { MesaConfig } from "./config";

export interface MesaContext {
  readonly registry: Readonly<RegistryClients>;
  readonly injectHeaders: Record<string, string>;
  configLoader(zone: Zone): Promise<MesaConfig>;
  practice(networkId: string): Readonly<PracticeClients>;
}

export const MesaContext = React.createContext<MesaContext>({
  get registry(): RegistryClients {
    throw new Error("MesaContext is not initialized");
  },
  injectHeaders: {},
  practice(): PracticeClients {
    throw new Error("MesaContext is not initialized");
  },
  configLoader(): Promise<MesaConfig> {
    throw new Error("MesaContext is not initialized");
  },
});

export const useRegistry = () => useContext(MesaContext).registry;
