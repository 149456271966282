import { HealthcareService, NoteDefinition, NoteSection, NoteSectionForm, NoteTemplate, Reference } from "@remhealth/apollo";
import { createVersionedReference } from "@remhealth/host";
import { createReferenceMapperById, createReferenceMapperByIdentifier } from "./referenceMapper";
import { importNoteSectionForms } from "./noteSectionForms";
import { ImportProps } from "./common";

export async function importNoteTemplates(props: ImportProps): Promise<NoteTemplate[]> {
  const {
    sourceClient,
    targetClient,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  let sourceNoteTemplates = await sourceClient.noteTemplates.feed({
    filters: [{
      status: {
        matches: "Active",
      },
    }],
  }).all({ abort });

  onUpdate(sourceNoteTemplates.length);
  if (sourceNoteTemplates.length === 0) {
    return [];
  }

  const targetNoteTemplates = await targetClient.noteTemplates.feed({
    filters: [{
      status: {
        matches: "Active",
      },
      includeDeleted: true,
    }],
  }).all({ abort });
  const noteTemplates = targetNoteTemplates.filter(f => !f.meta?.isDeleted);

  const sourceServices: Reference<HealthcareService>[] = [];
  const sourceNoteDefinitions: Reference<NoteDefinition>[] = [];
  const sourceNoteSectionForms: Reference<NoteSectionForm>[] = [];

  sourceNoteTemplates = sourceNoteTemplates.filter(item => {
    onItemCreate(item.id, item.name);

    if (targetNoteTemplates.some(d => d.id === item.id)) {
      onItemSkip(item.id, "IdExisted");
      return false;
    }

    const name = item.name.trim().toLowerCase();
    if (noteTemplates.some(d => d.name.trim().toLowerCase() === name && d.definition.id === item.definition.id)) {
      onItemError(item.id, "Duplicate name", true);
      return false;
    }

    sourceServices.push(...item.services);
    sourceNoteDefinitions.push(item.definition);

    item.sections.forEach(s => {
      if (s.form) {
        sourceNoteSectionForms.push(s.form);
      }
    });

    return true;
  });

  const noteSectionForms = new Map<string, NoteSectionForm>();
  const pageSize = 100;
  for (let i = 0; i < sourceNoteSectionForms.length; i += pageSize) {
    const page = sourceNoteSectionForms.slice(i, i + pageSize);
    const targetItems = await targetClient.noteSectionForms.feed({
      filters: [{ ids: page.map(i => i.id), includeDeleted: true }],
    }).all({ abort });

    for (const targetItem of targetItems) {
      noteSectionForms.set(targetItem.id, targetItem);
    }
  }

  const missingNoteSectionForms = sourceNoteSectionForms.filter(f => !noteSectionForms.has(f.id));
  if (missingNoteSectionForms.length) {
    const newNoteSectionForms = await importNoteSectionForms(
      {
        ...props,
        onItemCreate: (id: string, name: string) => onItemCreate(id, `CNS: ${name}`),
      },
      missingNoteSectionForms
    );

    newNoteSectionForms.forEach(f => {
      if (!noteSectionForms.has(f.id)) {
        noteSectionForms.set(f.id, f);
      }
    });
  }

  const serviceReferenceMapper = await createReferenceMapperByIdentifier(sourceClient.healthcareServices, targetClient.healthcareServices, sourceServices, abort);
  const noteDefinitionReferenceMapper = await createReferenceMapperById(targetClient.noteDefinitions, sourceNoteDefinitions, abort);

  const results = [];
  for (const noteTemplate of sourceNoteTemplates) {
    const item = copyNoteTemplate(noteTemplate);
    if (item) {
      try {
        const result = await targetClient.noteTemplates.update({ ...item, meta: undefined }, { abort });
        results.push(result);
        onItemComplete(noteTemplate.id);
      } catch (error) {
        onItemError(noteTemplate.id, "Failed to create", true);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }

  return results;

  function copyNoteTemplate(noteTemplate: NoteTemplate): NoteTemplate | null {
    const services = serviceReferenceMapper.map(noteTemplate.services, (unmatched) => {
      onItemError(noteTemplate.id, `Service "${unmatched.display}" not found`);
    });

    if (!noteTemplate.inheritServices && services.length === 0) {
      onItemError(noteTemplate.id, "No valid services", true);
      return null;
    }

    const definition = noteDefinitionReferenceMapper.map(noteTemplate.definition);
    if (!definition) {
      onItemError(noteTemplate.id, `Note type ${noteTemplate.definition.display} not found`, true);
      return null;
    }

    const sections: NoteSection[] = [];
    for (const s of noteTemplate.sections) {
      const section: NoteSection = {
        ...s,
        form: undefined,
      };

      if (s.form) {
        const form = noteSectionForms.get(s.form.id);
        if (form) {
          section.form = createVersionedReference(form);
        } else {
          onItemError(noteTemplate.id, `Form "${s.form.display}" not found in section "${s.name}"`);
          continue;
        }
      }

      sections.push(section);
    }

    if (sections.length === 0) {
      onItemError(noteTemplate.id, "No valid section", true);
      return null;
    }

    return {
      ...noteTemplate,
      services,
      definition,
      sections,
    };
  }
}
