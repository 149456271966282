import { IFeedIterator, Identifier, Reference, ResourceFilterSet, ResourceTypes, type ResponseOptions, groupBy, isReference } from "@remhealth/apollo";
import { createReference, createVersionedReference } from "@remhealth/host";

export interface ReferenceMapper<T extends ResourceTypes> {
  map<K extends Reference<T> | T>(source: K): K | undefined;
  map<K extends Reference<T> | T>(source: K[], unmatchHandler: (unmatchedSource: K) => void): K[];
  map<K extends Reference<T> | T>(source: K, versionedReference: true): Reference<T> | undefined;
  map<K extends Reference<T> | T>(source: K[], versionedReference: true, unmatchedHandler: (unmatchedSource: K) => void): Reference<T>[];
}

export interface ReferenceClient<T extends ResourceTypes> {
  feed(request: { filters: ResourceFilterSet[]; responseOptions?: ResponseOptions }): IFeedIterator<T>;
}

export async function createReferenceMapperById<T extends ResourceTypes>(
  targetClient: ReferenceClient<T>,
  source: (Reference<T> | T)[],
  abort: AbortSignal
): Promise<ReferenceMapper<T>> {
  const targetMap = new Map<string, T>(); // Map by ID
  const pageSize = 100;

  const list = [...new Set(source.map(i => i.id))];
  for (let i = 0; i < list.length; i += pageSize) {
    const page = list.slice(i, i + pageSize);
    const targetItems = await targetClient.feed({
      filters: [{ ids: page, includeDeleted: true }],
    }).all({ abort });

    for (const targetItem of targetItems) {
      targetMap.set(targetItem.id, targetItem);
    }
  }

  return { map: createMap(mapSingle) };

  function mapSingle(sourceReference: Reference<T> | T, versionedReference: boolean): Reference<T> | T | undefined {
    const match = targetMap.get(sourceReference.id);
    if (match) {
      return isReference(sourceReference)
        ? versionedReference ? createVersionedReference(match) : createReference(match)
        : match;
    }

    return undefined;
  }
}

export async function createReferenceMapperByIdentifier<T extends ResourceTypes>(
  sourceClient: ReferenceClient<T>,
  targetClient: ReferenceClient<T>,
  source: (Reference<T> | T)[],
  abort: AbortSignal
): Promise<ReferenceMapper<T>> {
  const sourceMap = new Map<string, T>(); // Map by ID
  const targetMap = new Map<string, T>(); // Map by identifier

  const referenceIds = new Set<string>();
  for (const item of source) {
    if (!isReference(item)) {
      if (!sourceMap.has(item.id)) {
        sourceMap.set(item.id, item);
      }
    } else {
      referenceIds.add(item.id);
    }
  }

  const pageSize = 100;

  const list = [...referenceIds];
  for (let i = 0; i < list.length; i += pageSize) {
    const page = list.slice(i, i + pageSize);
    const newSourceItems = await sourceClient.feed({
      filters: [{ ids: page, includeDeleted: true }],
    }).all({ abort });

    for (const sourceItem of newSourceItems) {
      sourceMap.set(sourceItem.id, sourceItem);
    }
  }

  const sourceItems = [...sourceMap.values()];

  for (let i = 0; i < sourceItems.length; i += pageSize) {
    const page = sourceItems.slice(i, i + pageSize);
    const identifiers = page.flatMap(i => i.identifiers ?? []);
    if (identifiers.length) {
      const targetItems = await targetClient.feed({
        filters: getIdentifierFilters(identifiers),
      }).all({ abort });

      for (const targetItem of targetItems) {
        if (targetItem.identifiers?.length) {
          const identiferKey = getKey(targetItem.identifiers);
          targetMap.set(identiferKey, targetItem);
        }
      }
    }
  }

  return { map: createMap(mapSingle) };

  function mapSingle(sourceReference: Reference<T> | T, versionedReference: boolean): Reference<T> | T | undefined {
    const source = sourceMap.get(sourceReference.id);
    if (!source) {
      return undefined;
    }

    if (source.identifiers?.length) {
      const identiferKey = getKey(source.identifiers);
      const match = targetMap.get(identiferKey);
      if (match) {
        return isReference(sourceReference)
          ? versionedReference ? createVersionedReference(match) : createReference(match)
          : match;
      }
    }

    return undefined;
  }
}

function getKey(identifiers: Identifier[]) {
  return identifiers
    .sort((a, b) => (a.system ?? "").localeCompare(b.system ?? "") || a.value.localeCompare(b.value))
    .map(i => `${i.system}|${i.value}`)
    .join("+")
    .toLowerCase();
}

function createMap<T extends ResourceTypes>(mapSingle: (sourceReference: Reference<T> | T, versionedReference: boolean ) => Reference<T> | T | undefined) {
  return map;

  function map(sourceReference: Reference<T> | T): Reference<T> | T | undefined;
  function map(sourceReferences: (Reference<T> | T)[], unmatchedHandler: (unmatchedSource: Reference<T> | T) => void): (Reference<T> | T)[];
  function map(sourceReferences: Reference<T> | T, versionedReference: boolean): Reference<T> | undefined;
  function map(sourceReferences: (Reference<T> | T)[], versionedReference: boolean, unmatchedHandler: (unmatchedSource: Reference<T> | T) => void): Reference<T>[];

  function map(
    arg1: Reference<T> | T | (Reference<T> | T)[],
    arg2?: ((unmatchedSource: Reference<T> | T) => void) | boolean,
    arg3?: ((unmatchedSource: Reference<T> | T) => void)
  ): Reference<T> | T | (Reference<T> |T)[] | Reference<T>[] | undefined {
    const hasFlag = typeof arg2 === "boolean";
    if (Array.isArray(arg1)) {
      return hasFlag ? mapArray(arg1, arg2, arg3!) : mapArray(arg1, false, arg2!);
    }
    return hasFlag ? mapSingle(arg1, arg2) : mapSingle(arg1, false);
  }

  function mapArray(
    sourceReferences: (Reference<T> | T)[],
    versionedReference: boolean,
    unmatchedHandler: ((unmatchedSource: Reference<T> | T) => void)
  ): (Reference<T> | T)[] | Reference<T>[] {
    const matched: (Reference<T> | T)[] = [];

    for (const sourceReference of sourceReferences) {
      const match = mapSingle(sourceReference, versionedReference);
      if (match) {
        matched.push(match);
      } else {
        unmatchedHandler(sourceReference);
      }
    }

    return matched;
  }
}

function getIdentifierFilters(identifiers: Identifier[]): ResourceFilterSet[] {
  const systemGroups = groupBy(identifiers, i => i.system);
  const filterSets: ResourceFilterSet[] = [];

  systemGroups.forEach((identifiers, system) => {
    if (system) {
      filterSets.push({
        identifier: {
          system,
          equalsAny: identifiers.map(i => i.value),
          ignoreCase: true,
        },
      });
    }
  });

  return filterSets;
}
