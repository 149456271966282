import { useContext, useEffect, useMemo, useState } from "react";
import { uniq } from "lodash-es";
import { ulid } from "ulid";
import { CloudDownload, Edit, SyncIssue } from "@remhealth/icons";
import { Features, New, Notation, PatientPopulationLevel, Practice, PracticeStatus, Product, systems } from "@remhealth/apollo";

import {
  DeprecatedReleaseFlags,
  MobileReleaseFlags,
  ReleaseFlags,
  createReference,
  deprecatedReleaseFlags,
  useAccessToken
} from "@remhealth/host";

import {
  BeforeLeavePrompt,
  Form,
  FormContent,
  Markup,
  Yup,
  getProductLabel,
  mastodonClient,
  useErrorHandler,
  useNotifier
} from "@remhealth/core";

import {
  Button,
  Callout,
  Card,
  Checkbox,
  Classes,
  Dialog,
  FormField,
  FormGroup,
  IconButton,
  InputGroup,
  Intent,
  NonIdealState,
  NumberDial,
  SelectInput,
  Spinner,
  Switch,
  useAbort,
  useDebouncedState
} from "@remhealth/ui";

import { ComposeEditor, CreateLink, EditableContent, Toolbar } from "@remhealth/compose";
import { MesaContext, useRegistry } from "~/contexts";
import { MesaConfig } from "~/config";
import { CareFabricItem, CareFabricSuggest } from "./careFabricSuggest";
import { Database, DatabaseSuggest, databaseTextRenderer } from "./databaseSuggest";
import { ReleaseFlagsSelect, allReleaseFlags } from "./releaseFlagsSelect";
import { CareFabricConnectionDialog } from "./careFabricConnectionDialog";
import { DialogBody } from "./common.styles";
import {
  ConnectionDetails,
  Container,
  FeaturesSection,
  Maintainence,
  NameInput,
  NotesCompose,
  NotesSection,
  NotesSectionHeader,
  SplitContainer
} from "./practiceEdit.styles";

type AllReleaseFlags = ReleaseFlags | MobileReleaseFlags | DeprecatedReleaseFlags;

interface PracticeFormValues {
  name: string;
  networkId: string;
  careFabricScope: string | undefined;
  features: Features[];
  releaseFlags: AllReleaseFlags[];
  exceptReleaseFlags: AllReleaseFlags[];
  allReleaseFlags: boolean;
  maxAmbientListenersAllowed?: number;
  database: Database | undefined;
  careFabricConnection: CareFabricItem | undefined;
  product: ProductChoice;
  status: PracticeStatus;
}

type ProductChoice = Product | "Unknown";
const isCareFabric = new Set<ProductChoice>([Product.myAvatar, Product.myEvolv, Product.GEHRIMED, Product.myUnity, Product.ClaimTrak]);

const schema = (ambientListenersFieldRequired: boolean) => Yup.object<PracticeFormValues>({
  name: Yup.string()
    .label("Name")
    .required(),

  networkId: Yup.string()
    .label("Network ID")
    .required(),

  careFabricScope: Yup.string()
    .label("CareFabric Scope")
    .when("careFabricConnection", {
      is: (careFabricConnection: CareFabricItem | undefined) => !!careFabricConnection,
      then: Yup.string().required("CareFabric Scope is required when a CareFabric Connection is selected."),
    }),

  features: Yup.array(),

  releaseFlags: Yup.array(),

  exceptReleaseFlags: Yup.array(),

  allReleaseFlags: Yup.boolean(),

  database: Yup.mixed<Database | undefined>(),

  careFabricConnection: Yup.mixed<CareFabricItem | undefined>(),

  product: Yup.mixed<ProductChoice>(),

  status: Yup.mixed<PracticeStatus>(),

  maxAmbientListenersAllowed: Yup.number().label("Number of users").requiredWhen(ambientListenersFieldRequired).moreThan(0),
});

const initialValues: PracticeFormValues = {
  name: "",
  networkId: "",
  careFabricScope: "",
  features: [],
  releaseFlags: [...deprecatedReleaseFlags],
  exceptReleaseFlags: [],
  allReleaseFlags: false,
  database: undefined,
  careFabricConnection: undefined,
  product: "Unknown",
  status: "Planned",
  maxAmbientListenersAllowed: undefined,
};

const products = Array.from<ProductChoice>(["Unknown"]).concat(Object.values(Product));
const practiceStatuses = Object.values(PracticeStatus);

interface LoadingMessage {
  title: string;
  description?: string;
}

export interface PracticeEditProps {
  config: MesaConfig;
  practice: Practice | null;
  onPracticeUpdate: (practice: Practice | null) => void;
}

export const PracticeEdit = (props: PracticeEditProps) => {
  const { config, practice, onPracticeUpdate } = props;

  const registry = useRegistry();
  const notification = useNotifier();
  const mesa = useContext(MesaContext);
  const token = useAccessToken();
  const handleError = useErrorHandler();
  const abort = useAbort();

  const [loading, setLoading] = useState<LoadingMessage>();
  const [syncing, setSyncing] = useState(false);
  const [syncResult, setSyncResult] = useState<Intent>(initializeSyncResult);
  const [cfConnectionOpen, setCfConnectionOpen] = useState(false);
  const [confirmDrop, setConfirmDrop] = useState(false);
  const [ambientListening, setAmbientListening] = useState(practice?.maxAmbientListenersAllowed !== undefined);
  const [confirmDropName, setConfirmDropName] = useDebouncedState("", 100);
  const [notes, setNotes] = useState<Notation>();
  const [editNotes, setEditNotes] = useState(false);

  const initialPracticeValues = useMemo<PracticeFormValues>(() => {
    if (!practice) {
      return initialValues;
    }

    const careFabricScope = practice.identifiers?.find(i => i.system === systems.careFabricScope)?.value;

    return {
      name: practice.name ?? "",
      networkId: practice.networkId,
      careFabricScope,
      features: practice.features,
      releaseFlags: allReleaseFlags.filter(flag => practice.releaseFlags.includes(flag)),
      exceptReleaseFlags: allReleaseFlags.filter(flag => practice.exceptReleaseFlags.includes(flag)),
      allReleaseFlags: practice.allReleaseFlags,
      database: practice.database ? {
        id: practice.database.id,
        display: practice.database.resource ? databaseTextRenderer(practice.database.resource) : practice.database.display ?? "** Hidden **",
      } : undefined,
      careFabricConnection: practice.careFabricConnection ? {
        id: practice.careFabricConnection.id,
        display: practice.careFabricConnection?.display ?? "** Hidden **",
      } : undefined,
      product: practice.product ?? "Unknown",
      status: practice.status,
      maxAmbientListenersAllowed: practice.maxAmbientListenersAllowed,
    };
  }, [practice]);

  useEffect(() => {
    loadNotes();
  }, [practice]);

  if (loading) {
    return (
      <NonIdealState description={loading.description} icon={<Spinner intent="primary" />} title={loading.title} />
    );
  }

  return (
    <Card>
      <Form<PracticeFormValues>
        validateOnChange
        initialValues={initialPracticeValues}
        validateOnBlur={false}
        validationSchema={schema(ambientListening)}
        onSubmit={handlePracticeSubmit}
      >
        {form => {
          const { fields } = form;
          const product = fields.product.value;

          return (
            <>
              <BeforeLeavePrompt when={form.dirty} />
              <Container>
                <SplitContainer>
                  <Container>
                    <FormGroup field={fields.name} label="Name">
                      <NameInput field={fields.name} />
                    </FormGroup>
                    <SplitContainer>
                      <FormGroup field={fields.product} label="Product">
                        <SelectInput<ProductChoice>
                          field={fields.product}
                          items={products}
                          optionRenderer={item => item}
                        />
                      </FormGroup>
                      <FormGroup field={fields.status} label="Status">
                        <SelectInput<PracticeStatus>
                          field={fields.status}
                          items={practiceStatuses}
                          optionRenderer={item => item}
                        />
                      </FormGroup>
                    </SplitContainer>
                  </Container>
                  <Container>
                    <FormGroup field={fields.networkId} label="Network Id">
                      <InputGroup disabled={practice?.databaseExists ? true : undefined} field={fields.networkId} />
                    </FormGroup>
                    {product && isCareFabric.has(product) && (
                      <FormGroup field={fields.careFabricScope} label="CareFabric Scope">
                        <InputGroup field={fields.careFabricScope} />
                      </FormGroup>
                    )}
                  </Container>
                </SplitContainer>
                <Container>
                  {practice && !practice.databaseExists && (
                    <Button intent="success" label="Deploy Apollo Database" onClick={handleCreateDatabase} />
                  )}
                  {practice?.databaseExists && (
                    <>
                      <Button icon={<CloudDownload />} label="Run practice sync" loading={syncing} onClick={handleSync} />
                      {!syncing && syncResult === "success" && (
                        <Callout icon="tick" intent="success">
                          Sync completed successfully.
                        </Callout>
                      )}
                      {!syncing && (syncResult === "warning" || syncResult === "danger") && (
                        <Callout icon={<SyncIssue />} intent={syncResult}>
                          The last attempted sync had {syncResult === "danger" ? "failures" : "warnings"}.  {practice.meta?.pullResult?.reason}
                        </Callout>
                      )}
                    </>
                  )}
                </Container>
                <SplitContainer>
                  <FeaturesSection>
                    <h5>Features</h5>
                    {renderFeatureSwitch(fields.features, "Bells", "bells.ai")}
                    {product !== "Unknown" && renderFeatureSwitch(fields.features, "DisableSync", "Disable Outbound Sync to " + getProductLabel(product))}
                    {renderFeatureSwitch(fields.features, "Mobile", "Mobile")}
                    {renderFeatureSwitch(fields.features, "OutcomeAssessments", "Outcome Assessments")}
                    {renderFeatureSwitch(fields.features, "Agenda", "Agenda")}
                    {renderFeatureSwitch(fields.features, "LongTermGoals", "Long Term Goals")}
                    {renderFeatureSwitch(fields.features, "EmrDirectSandbox", "Send EMR Direct mail to sandbox")}
                    {renderFeatureSwitch(fields.features, "Diagnoses", "Diagnosis")}
                    {renderFeatureSwitch(fields.features, "LoginCareFabric", "Login CareFabric")}
                    {renderFeatureSwitch(fields.features, "LoginNiamDev", "NIAM Dev")}
                    {renderFeatureSwitch(fields.features, "LoginNiamUat", "NIAM UAT")}
                    {renderFeatureSwitch(fields.features, "LoginNiamProd", "NIAM Prod")}
                    {renderFeatureSwitch(fields.features, "UserSystems", "Uses SubSystem Codes")}
                    {product === "ClaimTrak" && renderFeatureSwitch(fields.features, "Eligibility", "Eligibility")}
                    {product === "myAvatar" && renderFeatureSwitch(fields.features, "MyAvatarTheme", "myAvatar Theme")}
                    {product === "myEvolv" && renderFeatureSwitch(fields.features, "WorkgroupLevelAccess", "Workgroup Level Access", handleWorkgroupLevelAccessChange)}
                    <Checkbox checked={ambientListening} label="Bells Scribe" onChange={(e) => handleToggleAmbientListening(e, form)} />
                    {ambientListening && (
                      <FormGroup inline field={fields.maxAmbientListenersAllowed} label="Number of users">
                        <NumberDial field={fields.maxAmbientListenersAllowed} />
                      </FormGroup>
                    )}
                  </FeaturesSection>
                  <FeaturesSection>
                    <h5>Release Flags</h5>
                    <Switch field={fields.allReleaseFlags} label="Include All Release Flags" />
                    <FormGroup
                      field={fields.allReleaseFlags.value ? fields.releaseFlags : fields.exceptReleaseFlags}
                      helperText="Used to control which features are enabled for the practice."
                      label={fields.allReleaseFlags.value ? <><strong><em>Except</em></strong> the following flags:</> : null}
                    >
                      {!fields.allReleaseFlags.value
                        ? <ReleaseFlagsSelect allowCopy field={fields.releaseFlags} label="Choose release flags..." practiceId={practice?.id} product={fields.product.value} />
                        : <ReleaseFlagsSelect field={fields.exceptReleaseFlags} label="Exclude these release flags..." practiceId={practice?.id} product={fields.product.value} />}
                    </FormGroup>
                  </FeaturesSection>
                  <NotesSection>
                    {notes && (
                      <>
                        <NotesSectionHeader>
                          <h5>Notes</h5>
                          {!editNotes && <IconButton minimal square aria-label="Edit" icon={<Edit />} onClick={handleEditNotesClick} />}
                        </NotesSectionHeader>
                        {!editNotes
                          ? <Markup source={notes.content.value} />
                          : (
                            <>
                              <NotesCompose className="editor" initialValue={notes.content.value} onChange={handleNotesChange}>
                                <EditableContent maxLength={10000} />
                                <CreateLink />
                                <Toolbar />
                              </NotesCompose>
                              <div>
                                <Button intent="primary" label="Save" onClick={handleSaveNotesClick} />
                                <Button minimal label="Cancel" onClick={handleCancelNotesClick} />
                              </div>
                            </>
                          )}
                      </>
                    )}
                  </NotesSection>
                </SplitContainer>
                {product === "ClaimTrak" && (
                  <FormGroup field={fields.database} label="Database">
                    <DatabaseSuggest field={fields.database} />
                  </FormGroup>
                )}
                {isCareFabric.has(product) && (
                  <SplitContainer>
                    <FormGroup field={fields.careFabricConnection} label="CareFabric Connection">
                      <CareFabricSuggest field={fields.careFabricConnection} />
                    </FormGroup>
                    {practice?.careFabricConnection && <ConnectionDetails label="Show details" onClick={() => setCfConnectionOpen(true)} />}
                    <CareFabricConnectionDialog
                      connection={practice?.careFabricConnection}
                      isOpen={cfConnectionOpen}
                      onClose={() => setCfConnectionOpen(false)}
                    />
                  </SplitContainer>
                )}
                <Button
                  large
                  disabled={form.isSubmitting}
                  intent="primary"
                  label="Save"
                  onClick={() => form.submitForm()}
                />
              </Container>
            </>
          );
        }}
      </Form>
      {!practice?.databaseExists && <Button minimal intent="danger" label="Delete Practice" onClick={handleDeleteClick} />}
      {practice?.databaseExists && (
        <Maintainence>
          <h5>Maintenance</h5>
          <Button minimal intent="danger" label="Drop Apollo Database" onClick={handleDropClick} />
          <Dialog
            isCloseButtonShown
            isOpen={confirmDrop}
            title="Are you sure?"
            onClose={cancelDrop}
          >
            <DialogBody className={Classes.DIALOG_BODY}>
              <FormGroup label={<>Type <strong>{practice.networkId}</strong> to drop the database</>}>
                <InputGroup fill onChange={setConfirmDropName} />
              </FormGroup>
            </DialogBody>
            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button label="Cancel" onClick={cancelDrop} />
                <Button
                  disabled={confirmDropName !== practice.networkId}
                  intent="danger"
                  label="Drop Database"
                  onClick={handleConfirmDrop}
                />
              </div>
            </div>
          </Dialog>
        </Maintainence>
      )}
    </Card>
  );

  function handleToggleAmbientListening(event: React.FormEvent<HTMLInputElement>, form: FormContent<PracticeFormValues>) {
    const checked = event.currentTarget.checked;
    setAmbientListening(checked);
    if (!checked) {
      form.fields.maxAmbientListenersAllowed?.onChange(undefined);
    }
  }

  function initializeSyncResult() {
    switch (practice?.meta?.pullResult?.outcome) {
      case "Failed": return Intent.DANGER;
      case "Warning": return Intent.WARNING;
      default: return Intent.NONE;
    }
  }

  async function handleSync() {
    if (!practice) {
      return;
    }

    setSyncing(true);

    try {
      const ehrService = mastodonClient(config.urls.ehr, {
        networkId: practice.networkId,
        accessToken: token,
        headers: mesa.injectHeaders,
      });
      await ehrService.pullPractice(abort.signal);

      notification.show({ message: "Practice sync has been queued.", intent: "success" });
    } catch (error) {
      handleError(error);
    } finally {
      setSyncing(false);
    }
  }

  function renderFeatureSwitch(features: FormField<Features[]>, flag: Features, label: string, onToggleCallback?: (value: boolean) => void) {
    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        features.onChange([...features.value, flag]);
      } else {
        features.onChange(features.value.filter(v => v !== flag));
      }

      onToggleCallback?.(event.currentTarget.checked);
    };

    const checked = features.value.includes(flag);

    return (
      <Checkbox
        checked={checked}
        label={label}
        onChange={onChange}
      />
    );
  }

  async function handleWorkgroupLevelAccessChange(enabled: boolean) {
    if (enabled && practice) {
      const practiceClient = mesa.practice(practice.networkId);
      const response = await practiceClient.practicePreferences.query({});
      if (response.results.length > 0) {
        const preferences = response.results[0];
        if (preferences.patientPopulationLevel !== PatientPopulationLevel.All) {
          preferences.patientPopulationLevel = PatientPopulationLevel.All;
          await practiceClient.practicePreferences.update(preferences);
        }
      }
    }
  }

  async function handlePracticeSubmit(values: PracticeFormValues) {
    try {
      setLoading({ title: "Saving..." });

      const newPractice: New<Practice> = {
        name: values.name,
        networkId: values.networkId,
        features: values.features,
        releaseFlags: uniq([...values.releaseFlags, ...deprecatedReleaseFlags]),
        exceptReleaseFlags: values.allReleaseFlags ? uniq(values.exceptReleaseFlags) : [],
        allReleaseFlags: values.allReleaseFlags,
        databaseExists: practice?.databaseExists ?? false,
        database: values.database ? { id: values.database.id, display: values.database.display } : undefined,
        careFabricConnection: values.careFabricConnection ? { id: values.careFabricConnection.id, display: values.careFabricConnection.display } : undefined,
        product: values.product !== "Unknown" ? values.product : undefined,
        status: values.status,
        maxAmbientListenersAllowed: values.maxAmbientListenersAllowed,
      };

      newPractice.identifiers = practice?.identifiers ?? [];

      if (values.careFabricScope && values.product && isCareFabric.has(values.product)) {
        const identifier = newPractice.identifiers.find(i => i.system === systems.careFabricScope);
        if (!identifier) {
          newPractice.identifiers.push({ system: systems.careFabricScope, value: values.careFabricScope });
        } else {
          identifier.value = values.careFabricScope;
        }
      } else {
        newPractice.identifiers = newPractice.identifiers.filter(i => i.system !== systems.careFabricScope);
      }

      let saved: Practice;

      if (practice === null) {
        saved = await registry.practices.create(newPractice);
      } else {
        saved = await registry.practices.update({
          ...practice,
          ...newPractice,
        });
      }

      setSyncResult("none");
      onPracticeUpdate(saved);
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(undefined);
    }
  }

  function handleDropClick() {
    setConfirmDrop(true);
  }

  function cancelDrop() {
    setConfirmDrop(false);
  }

  async function handleCreateDatabase() {
    if (practice) {
      setLoading({ title: "Creating database...", description: "This can take a few minutes." });
      try {
        await registry.practices.createDatabase(practice.networkId);
        await refreshPractice(practice.id);
      } finally {
        setLoading(undefined);
      }
    }
  }

  async function handleDeleteClick() {
    if (practice) {
      await registry.practices.deleteById(practice.id);
      onPracticeUpdate(null);
    }
  }

  async function handleConfirmDrop() {
    setConfirmDrop(false);

    if (practice) {
      setLoading({ title: "Dropping database..." });
      try {
        await registry.practices.deleteDatabase(practice.networkId);
        await refreshPractice(practice.id);
      } finally {
        setLoading(undefined);
      }
    }
  }

  async function refreshPractice(practiceId: string) {
    const practice = await registry.practices.fetchById(practiceId);
    onPracticeUpdate(practice);
    return practice;
  }

  function handleNotesChange(editor: ComposeEditor) {
    if (!notes) {
      return;
    }
    notes.content.value = editor.getHtml();
    notes.content.plainText = editor.getText();
  }

  async function handleEditNotesClick() {
    setEditNotes(true);
  }

  function handleCancelNotesClick() {
    setEditNotes(false);
  }

  async function handleSaveNotesClick() {
    if (!notes) {
      return;
    }

    const updatedNotes = await mesa.registry.notations.update(notes, { abort: abort.signal });
    setNotes(updatedNotes);
    setEditNotes(false);
  }

  async function loadNotes(): Promise<void> {
    if (practice) {
      const response = await mesa.registry.notations.query({
        filters: [{ practice: { in: [practice.id] } }],
        abort: abort.signal,
      });

      return setNotes(response.results.length ? response.results[0] : {
        practice: createReference(practice),
        content: { value: "", plainText: "" },
        resourceType: "Notation",
        id: ulid(),
      });
    }
  }
};
