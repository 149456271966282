import { TabProps as CoreTabProps, Utils } from "@blueprintjs/core";
import { IconName, IconProps } from "./icon";
import type { TagProps } from "./tag";

export interface TabProps extends Omit<CoreTabProps, "children" | "icon"> {
  title: string;
  icon?: IconName | React.ReactElement<IconProps>;
  titleElement?: React.ReactNode;
  tagProps?: Omit<TagProps, "children">;
}

export function Tab(_props: TabProps) {
  // This component is never rendered directly.
  // @see ../tabs#renderTabPanel
  return <></>;
}

Tab.displayName = "Tab";

export type TabElement = React.ReactElement<TabProps & { children: React.ReactNode }>;

export function isTabElement(child: any): child is TabElement {
  return Utils.isElementOfType(child, Tab);
}
