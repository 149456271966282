import { useContext, useEffect, useState } from "react";
import { Link, generatePath, matchPath, useLocation, useMatch, useNavigate } from "react-router-dom";
import { Error, Office } from "@remhealth/icons";
import { Practice, zone as apolloZone } from "@remhealth/apollo";
import { BreadcrumbProps, Breadcrumbs, NonIdealState, Spinner, Tab, Tabs, useAbort } from "@remhealth/ui";
import { ApolloContext, PreferencesContext, PreferencesStore, type Zone, useAccessToken } from "@remhealth/host";
import {
  AnalyticsContext,
  EhrContext,
  StoreContext,
  athenaQueryClient,
  mastodonClient,
  storeFactory
} from "@remhealth/core";
import { MesaContext } from "~/contexts";
import { MesaConfig } from "~/config";
import { PracticeAreaTabId, operationRoutes, practiceAreaTabs } from "../routes";
import { PracticeEdit } from "./practiceEdit";
import { UsersGrid } from "./usersGrid";
import { DataImport } from "./dataImport";
import { Body, Container, Header } from "./practiceArea.styles";

export interface PracticeAreaProps {
  config: MesaConfig;
  zone: Zone;
}

type MesaPracticeContext =
  & ApolloContext
  & EhrContext
  & StoreContext
  & AnalyticsContext
  & PreferencesContext
  & { practice: Practice };

export const PracticeArea = (props: PracticeAreaProps) => {
  const { config, zone } = props;

  const mesa = useContext(MesaContext);
  const token = useAccessToken();
  const navigate = useNavigate();
  const location = useLocation();
  const abort = useAbort();
  const match = useMatch(operationRoutes.practice);

  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [tabId, setTabId] = useState("practice");
  const [practiceContext, setPracticeContext] = useState<MesaPracticeContext | "invalid" | null>(null);

  useEffect(() => {
    if (match?.params.networkId && match?.params.networkId !== "new") {
      loadPractice(match?.params.networkId);
    } else {
      setPracticeContext(null);
    }
  }, [match?.params.networkId]);

  useEffect(() => {
    updateTab();
  }, [location.key]);

  if (awaitingResponse) {
    return (
      <NonIdealState
        icon={<Spinner intent="primary" />}
        title="Loading practice session"
      />
    );
  }

  if (practiceContext === "invalid") {
    return (
      <NonIdealState
        description="That practice was not found in the database."
        icon={<Error />}
        title="Invalid Practice"
      />
    );
  }

  const practice = practiceContext?.practice ?? null;

  const breadcrumbs: BreadcrumbProps[] = [
    { icon: <Office />, text: <Link to={generatePath(operationRoutes.tab, { tab: "practices" })}>Practices</Link> },
    { text: practiceContext?.practice.name ?? "New Practice" },
  ];

  if (practiceContext) {
    breadcrumbs.push({ text: practiceAreaTabs[tabId as PracticeAreaTabId] });
  }

  return (
    <Container>
      <Header>
        <Breadcrumbs items={breadcrumbs} />
      </Header>
      <Body>
        <Tabs
          renderActiveTabPanelOnly
          vertical
          id="navbar"
          selectedTabId={tabId}
          onChange={handleTabChange}
        >
          <Tab id="practice" panel={<PracticeEdit config={config} practice={practice} onPracticeUpdate={handlePracticeUpdate} />} title={practiceAreaTabs.practice} />
          <Tab disabled={!practice?.databaseExists} id="users" panel={renderPanel(practice => <UsersGrid practice={practice} />)} title={practiceAreaTabs.users} />
          {zone === "prod" && <Tab disabled={!practice?.databaseExists} id="import" panel={renderPanel(practice => <DataImport practice={practice} />)} title={practiceAreaTabs.import} />}
        </Tabs>
      </Body>
    </Container>
  );

  function renderPanel(panel: (practice: Practice) => JSX.Element) {
    if (!practiceContext || practiceContext === "invalid") {
      return undefined;
    }

    return (
      <ApolloContext.Provider value={practiceContext}>
        <EhrContext.Provider value={practiceContext}>
          <StoreContext.Provider value={practiceContext}>
            <AnalyticsContext.Provider value={practiceContext}>
              <PreferencesContext.Provider value={practiceContext}>
                {panel(practiceContext.practice)}
              </PreferencesContext.Provider>
            </AnalyticsContext.Provider>
          </StoreContext.Provider>
        </EhrContext.Provider>
      </ApolloContext.Provider>
    );
  }

  function updateTab() {
    const match = matchPath(operationRoutes.practice, location.pathname);
    if (match) {
      if (match.params.practiceTab === "user-import") {
        setTabId("users");
      } else {
        setTabId(match.params.practiceTab ?? "practice");
      }
    }
  }

  function handleTabChange(tab: PracticeAreaTabId) {
    if (!practice || practiceContext === "invalid") {
      return;
    }

    const path = generatePath(operationRoutes.practice, { networkId: practice.networkId, tab: "practices", practiceTab: tab });
    navigate(path, { replace: true });
  }

  function handlePracticeUpdate(practice: Practice | null) {
    if (practice) {
      if (practiceContext && practiceContext !== "invalid") {
        setPracticeContext({ ...practiceContext, practice });
      }

      const path = generatePath(operationRoutes.practice, { networkId: practice.networkId, tab: "practices", practiceTab: tabId });
      navigate(path, { replace: true });
    } else {
      setPracticeContext(null);

      const path = generatePath(operationRoutes.tab, { tab: "practices" });
      navigate(path);
    }
  }

  async function loadPractice(networkId: string) {
    setAwaitingResponse(true);

    try {
      const feed = await mesa.registry.practices.fetch({
        fetchOptions: {
          networkId,
        },
        responseOptions: {
          expansions: ["Database"],
        },
        abort: abort.signal,
      });

      if (feed.results.length === 0) {
        setPracticeContext("invalid");
      } else {
        const practice = feed.results[0];

        const apollo = {
          ...mesa.registry,
          ...mesa.practice(practice.networkId),
        };

        const store = await storeFactory(practice.networkId, "mesa").create(apollo);

        // Purge cache each time
        store.purgeCache();

        const preferences = new PreferencesStore(apollo, practice);
        const ehrService = mastodonClient(config.urls.ehr, {
          networkId: practice.networkId,
          accessToken: token,
          headers: mesa.injectHeaders,
        });

        const analytics = athenaQueryClient(config.urls.athena, {
          zone: apolloZone[zone],
          networkId: practice.networkId,
          accessToken: token,
          headers: mesa.injectHeaders,
        });

        setPracticeContext({
          apollo,
          store,
          ehrService,
          analytics,
          preferences,
          practice,
        });
      }
    } finally {
      setAwaitingResponse(false);
    }
  }
};
