import { ParameterPresence, Snippet } from "@remhealth/apollo";
import { ImportProps } from "./common";

export async function importSnippets(props: ImportProps): Promise<Snippet[]> {
  const {
    sourceClient,
    targetClient,
    abort,
    onUpdate,
    onItemCreate,
    onItemSkip,
    onItemError,
    onItemComplete,
  } = props;

  const sourceSnippets = await sourceClient.snippets.feed({
    filters: [{ author: { presence: ParameterPresence.NotPresent } }],
  }).all({ abort });

  onUpdate(sourceSnippets.length);
  if (sourceSnippets.length === 0) {
    return [];
  }

  const targetSnippets = await targetClient.snippets.feed({
    filters: [{ author: { presence: ParameterPresence.NotPresent }, includeDeleted: true }],
  }).all({ abort });
  const snippets = targetSnippets.filter(f => !f.meta?.isDeleted);

  const results = [];
  for (const snippet of sourceSnippets) {
    onItemCreate(snippet.id, snippet.name);

    if (targetSnippets.some(d => d.id === snippet.id)) {
      onItemSkip(snippet.id, "IdExisted");
      continue;
    }

    const name = snippet.name.trim().toLowerCase();
    if (snippets.some(d => d.name.toLowerCase() === name)) {
      onItemError(snippet.id, "Duplicate name", true);
      continue;
    }

    try {
      const result = await targetClient.snippets.update({ ...snippet, meta: undefined }, { abort });
      results.push(result);
      onItemComplete(snippet.id);
    } catch (error) {
      onItemError(snippet.id, "Failed to create", true);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return results;
}
