import { KeyboardEvent, useState } from "react";
import { isEqual, uniq } from "lodash-es";
import { VerbAlternative } from "@remhealth/apollo";
import { Button, FormGroup, InputGroup, Tooltip, isEnterKeyPress, useAbort } from "@remhealth/ui";
import { DragHandleVertical } from "@remhealth/icons";
import { useErrorHandler } from "~/app";
import { useGlobalLanguageService } from "~/services";
import { RuleForm } from "../schema";
import { LemmaInput } from "./lemmaInput";
import { SuggestionList } from "./staffVerbRulePanel.styles";

export interface StaffVerbRulePanelProps {
  form: RuleForm<"StaffVerb" | "StaffVerbPatient" | "StaffVerbObject" | "StaffVerbRepeated">;
}

export function StaffVerbRulePanel(props: StaffVerbRulePanelProps) {
  const { form } = props;

  const lang = useGlobalLanguageService();
  const abort = useAbort();
  const handleError = useErrorHandler();
  const [addingSuggestion, setAddingSuggestion] = useState(false);
  const [duplicateWarning, setDuplicateWarning] = useState(false);
  const [newSuggestion, setNewSuggestion] = useState("");

  const addSuggestion = (
    <Tooltip isOpen content="This suggestion is already present in the list" disabled={!duplicateWarning} intent="danger" openOnTargetFocus={false} placement="top-start">
      <InputGroup
        maxLength={100}
        placeholder="Enter new alternative..."
        readOnly={addingSuggestion}
        rightElement={<Button square disabled={!newSuggestion.trim()} label="Add" loading={addingSuggestion} onClick={handleAddSuggestion} />}
        value={newSuggestion}
        onBlur={handleSuggestionBlur}
        onChange={setNewSuggestion}
        onKeyDown={handleSuggestionKeyDown}
      />
    </Tooltip>
  );

  const alternatives = form.alternatives.value.map(alt => {
    const verbs = [...alt.vb, ...alt.vbd, ...alt.vbg, ...alt.vbn, ...alt.vbp, ...alt.vbp, ...alt.vbz];
    return uniq(verbs).sort().join(", ");
  });

  return (
    <>
      <LemmaInput field={form.verb} helperText="Write a verb" label="Verb" maxLength={100} placeholder="Enter a verb..." pos="VERB" />
      <FormGroup field={form.instruction} helperText="Give some rationale or advice to help explain the recommendation" label="Instructions">
        <InputGroup field={form.instruction} maxLength={300} placeholder="Write instruction..." />
      </FormGroup>
      <FormGroup field={form.alternatives} helperText="Suggested replacements in preferred order" label="Alternatives to suggest">
        {addSuggestion}
        {alternatives.length > 0 && (
          <SuggestionList
            removable
            sortable={alternatives.length > 1}
            tagProps={{ icon: alternatives.length > 1 ? <DragHandleVertical /> : undefined }}
            values={alternatives}
            onRemove={handleAlternativeRemove}
            onSort={handleAlternativeSort}
          />
        )}
      </FormGroup>
    </>
  );

  function handleAlternativeSort(source: number, destination: number) {
    const alternatives = [...form.alternatives.value];
    const item = alternatives[source];
    alternatives.splice(source, 1);
    alternatives.splice(destination, 0, item);
    form.alternatives.onChange(alternatives);
    form.alternatives.onTouched();
  }

  function handleAlternativeRemove(_display: string, index: number) {
    const alternatives = [...form.alternatives.value];
    alternatives.splice(index, 1);
    form.alternatives.onChange(alternatives);
    form.alternatives.onTouched();
  }

  function handleSuggestionKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    setDuplicateWarning(false);

    if (isEnterKeyPress(event)) {
      handleAddSuggestion();
    }
  }

  function handleSuggestionBlur() {
    setDuplicateWarning(false);
  }

  async function handleAddSuggestion() {
    const [verb, ...suffix] = newSuggestion.trim().split(" ");

    if (!verb) {
      setNewSuggestion("");
      return;
    }

    const addSuffix = (verb: string) => [verb, ...suffix].join(" ");

    setAddingSuggestion(true);

    try {
      const results = await lang.inflections(verb, "VERB", abort.signal);

      const alternative: VerbAlternative = {
        vb: (results.inflections.VB ?? []).map(addSuffix),
        vbd: (results.inflections.VBD ?? []).map(addSuffix),
        vbg: (results.inflections.VBG ?? []).map(addSuffix),
        vbn: (results.inflections.VBN ?? []).map(addSuffix),
        vbp: (results.inflections.VBP ?? []).map(addSuffix),
        vbz: (results.inflections.VBZ ?? []).map(addSuffix),
      };

      if (
        alternative.vb.length > 0
        || alternative.vbd.length > 0
        || alternative.vbg.length > 0
        || alternative.vbn.length > 0
        || alternative.vbp.length > 0
        || alternative.vbz.length > 0
      ) {
        // Avoid duplicates
        const alreadyExists = form.alternatives.value.some(alt => isEqual(alt, alternative));

        if (alreadyExists) {
          setDuplicateWarning(true);
          return;
        }

        setNewSuggestion("");
        setDuplicateWarning(false);

        form.alternatives.onChange([...form.alternatives.value, alternative]);
        form.alternatives.onTouched();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setAddingSuggestion(false);
    }
  }
}
