import { KeyboardEvent, useState } from "react";
import { Button, FormGroup, InputGroup, Tooltip, isEnterKeyPress } from "@remhealth/ui";
import { DragHandleVertical } from "@remhealth/icons";
import { RuleForm } from "../schema";
import { LemmaInput } from "./lemmaInput";
import { SuggestionList } from "./lonelyNounRulePanel.styles";

export interface LonelyNounRulePanelProps {
  form: RuleForm<"LonelyNoun">;
}

export function LonelyNounRulePanel(props: LonelyNounRulePanelProps) {
  const { form } = props;

  const [newSuggestion, setNewSuggestion] = useState("");
  const [duplicateWarning, setDuplicateWarning] = useState(false);

  const addSuggestion = (
    <Tooltip isOpen content="This adjective is already present in the list" disabled={!duplicateWarning} intent="danger" openOnTargetFocus={false} placement="top-start">
      <InputGroup
        maxLength={100}
        placeholder="Enter new adjective..."
        rightElement={<Button square disabled={!newSuggestion.trim()} label="Add" onClick={handleAddSuggestion} />}
        value={newSuggestion}
        onBlur={handleSuggestionBlur}
        onChange={setNewSuggestion}
        onKeyDown={handleSuggestionKeyDown}
      />
    </Tooltip>
  );

  return (
    <>
      <LemmaInput field={form.noun} label="Noun" maxLength={100} placeholder="Enter a noun..." pos="NOUN" />
      <FormGroup field={form.instruction} helperText="Give some rationale or advice to help explain the recommendation" label="Instructions">
        <InputGroup field={form.instruction} maxLength={300} placeholder="Write instruction..." />
      </FormGroup>
      <FormGroup field={form.adjectives} helperText="Suggested adjectives in preferred order" label="Adjectives to suggest">
        {addSuggestion}
        {form.adjectives.value.length > 0 && (
          <SuggestionList
            removable
            sortable={form.adjectives.value.length > 1}
            tagProps={{ icon: form.adjectives.value.length > 1 ? <DragHandleVertical /> : undefined }}
            values={form.adjectives.value}
            onChange={handleAdjectiveChange}
          />
        )}
      </FormGroup>
    </>
  );

  function handleAdjectiveChange(adjectives: string[]) {
    form.adjectives.onChange(adjectives);
    form.adjectives.onTouched();
  }

  function handleSuggestionKeyDown(event: KeyboardEvent<HTMLInputElement>) {
    setDuplicateWarning(false);

    if (isEnterKeyPress(event)) {
      handleAddSuggestion();
    }
  }

  function handleSuggestionBlur() {
    setDuplicateWarning(false);
  }

  async function handleAddSuggestion() {
    let adjective = newSuggestion.trim();

    if (adjective !== adjective.toUpperCase()) {
      adjective = adjective.toLowerCase();
    }

    // If the user mistakenly wrote their noun as part of the suggestion, remove it
    const noun = form.noun.value.toLowerCase();
    if (adjective.endsWith(noun)) {
      adjective = adjective.slice(0, -noun.length).trim();
    }

    if (!adjective) {
      return;
    }

    // Avoid duplicates
    const alreadyExists = form.adjectives.value.includes(adjective);

    if (alreadyExists) {
      setDuplicateWarning(true);
      return;
    }

    setNewSuggestion("");
    setDuplicateWarning(false);

    form.adjectives.onChange([...form.adjectives.value, adjective]);
    form.adjectives.onTouched();
  }
}
