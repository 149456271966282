import styled from "styled-components";
import { Button, Card, MenuItem } from "@remhealth/ui";
import { ProductToggle } from "../components";

export const SendInvitesMessage = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-top: 5px;
`;

export const CheckboxHeader = styled.th`
  min-width: 35px;
  padding: 0;
  text-align: center;
`;

export const CheckboxCell = styled.td`
  padding: 0;
  text-align: center;
`;

export const PermissionsHeader = styled.th`
  min-width: 206px;
`;

export const SourceButton = styled(Button)`
  width: 100px;
  font-size: 12px;
`;

export const SourceBar = styled(ProductToggle)`
  padding: 10px 0px 20px 0px;
`;

export const SearchBarContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  .bp5-button {
    white-space: nowrap;
    flex: 0 0 auto;
  }
`;

export const NiamHeader = styled.th`
  text-align: center;
`;

export const ActionsHeader = styled.th`
  width: 40px;
`;

export const NameHeader = styled.th`
  min-width: 150px;
`;

export const EmailCell = styled.td`
  max-width: 300px;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const NiamCell = styled.td`
  text-align: center;
`;

export const ExtensionMenuItem = styled(MenuItem)`
  font-family: monospace;
  font-size: var(--font-size-small);
`;
